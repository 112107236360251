<template>
  <div class="preloader">
    <div class="preloader__icon">
      <img src="../../public/img/appointment-widget-images/preloader-spinner.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped lang="sass">
  .preloader
    display: flex
    align-items: center
    justify-content: center
    background: rgba(#000, .15)
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 999
</style>


