<template>
  <div class="selection-list">
    <label class="select-search selection-list__input" :class="{isFocused}">
      <input
        type="text" v-model="search"
        :disabled="!!selectedData"
        class="select-search__input"
        placeholder="Поиск по списку"
        @focus="isFocused = true"
        @blur="isFocused = false"
      >
      <div class="select-search__icon" v-if="!search.length">
        <img src="../../public/img/appointment-widget-images/search.svg" alt="imma.ru"/>
      </div>
      <div class="select-search__icon" style="cursor: pointer;" v-else @click="search = ''">
        <img class="cross" src="../../public/img/appointment-widget-images/cross.svg" alt="imma.ru"/>
      </div>
    </label>
    <div class="list">
      <div class="list__item"
            v-for="item in filteredItems"
            :key="item.id"
            @click="onClick(item)"
            :class="{active: isSelectedItem(item)}"
          >
        <div class="list__circle"></div>

        <div v-if="type === 'doctors'"
            class="list__avatar"
            :class="{'default': !hasAvatarDoctor(item.id)}">
          <img :src="getDoctorAvatar(item.id)">
        </div>

        <div class="list__value">
          {{printLabelText(item)}}
          <div class="doctor-degree" v-if="type === 'doctors' && getDoctorDegree(item.id)">
            <v-popover
                targetClasses="tooltip"
                offset="10"
                classes="info"
                placement="top-center"
                trigger="hover"
            >
                <img src="../../public/img/appointment-widget-images/hat-icon.svg" class="tooltip-target b3">
                <template slot="popover">
                    <ul>
                        <li v-for="(degree, index) in getDoctorDegree(item.id)"
                            :key="index">{{degree.title}}</li>
                    </ul>
                </template>
            </v-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultAvatar from '../../public/img/appointment-widget-images/doctors-avatar-placeholder.svg';

export default {
  props: ['data', 'type', 'onSelect', 'selectedData', 'clearSelected'],
  data () {
    return {
      search: '',
      doctorsAvatarData: [],
      isFocused: false
    }
  },
  mounted(){
    this.fetchDoctorAvatars()
  },
  methods: {
    fetchDoctorAvatars(){
      fetch('/doctors/avatars')
          .then(res => res.json())
          .then(data => this.doctorsAvatarData = data)
          .catch(e => {throw e})
    },
    isSelectedItem(item){
      if (this.selectedData && Array.isArray(this.selectedData)) {
        return this.selectedData[0].id == item.id
      }
      if(this.selectedData && this.selectedData instanceof Object){
        return this.selectedData.id == item.id
      }
    },
    formatDoctorName: function (value) {
      if (!value) return ''
      value = value.split(' ');
      return `${value[0]} ${value[1] && value[1][0]}.${value[2] && value[2][0]}.`
    },

    onClick: function(item) {
      if( this.isSelectedItem(item) ) {
        this.clearSelected(this.type);
        return;
      }
      this.onSelect({ field: this.type, searchType: 'additional' }, item)
    },

    getDoctorDegree(id){
      const doctorWithDegree = this.doctorsAvatarData.find(item => {
        return (item.id == id) && (item.degree.length > 0)
      });
      return doctorWithDegree ? doctorWithDegree.degree : null
    },

    getDoctorAvatar(id){
      const currentItem = this.doctorsAvatarData.find(item => item.id == id);
      return currentItem
              ? currentItem.imgSrc
              : defaultAvatar;
    },

    hasAvatarDoctor(id){
      if(this.doctorsAvatarData && this.doctorsAvatarData.length > 0){
        return this.doctorsAvatarData.find(item => item.id == id)
      } else {
        return false
      }
    },

    printLabelText(props){
      if(this.type === 'clinics') return `${props.name} (м.${props.metro})`
      if(this.type === 'doctors') return this.formatDoctorName(props.name)
      return props.name
    }
  },

  computed: {
    filteredItems() {
      return this.data.filter(item => {
         return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    }
  }
}
</script>

<style scoped lang="sass">
  input.search-input
    display: none
    height: 40px!important
    line-height: 40px!important
    font-size: 15px!important
    &::placeholder
      font-size: 15px!important

    @media screen and (max-width: 700px)
      display: block
  .list
    height: 300px
    overflow-y: auto
    border-right: 2px solid #eeeeee
    padding-right: 25px
    font-size: 15px
    &__item
      display: flex
      align-items: center
      color: #282828
      margin-bottom: 25px
      cursor: pointer
      transition: .3s
      &:hover, &.active
        color: #139594
        .list__circle
          border-color: #139594
    &__label
      font-size: 14px
      font-weight: 500
      margin-bottom: 10px
    &__circle
      width: 16px
      height: 16px
      border: 3px solid #d2d4d7
      border-radius: 50%
      margin-right: 10px
      transition: .3s
      flex-shrink: 0
      margin-top: 2px

    &__value
      display: flex
      justify-content: space-between
      flex-grow: 1
      line-height: 1.35

    &__avatar
      width: 38px
      height: 38px
      border-radius: 50%
      overflow: hidden
      margin-right: 15px
      background: #EEEEEE
      flex-shrink: 0
      img
        width: 100%
        height: 100%
        object-fit: cover

      &.default
        display: flex
        align-items: center
        justify-content: center
        img
          opacity: .2
          width: 25px
          object-fit: unset
    .doctor-degree
      right: 20px
      width: 20px
      opacity: .4
      margin-left: 25px
      margin-right: 0
      display: flex
      align-items: center

      img
        height: 13px
        max-width: 100%

    @media screen and (max-width: 1260px)
      height: auto
      max-height: 300px

    @media screen and (max-width: 1100px)
      font-size: 14px

    @media screen and (max-width: 700px)
      margin-top: 25px

  .selection-list
    &__input
      margin: 0 0 44px
      display: block
  .select-search
    width: calc(100% - 40px)
    position: relative
    border-bottom: 1px solid #B0B0B0
    transition: 0.3s

    &.isFocused
      border-color: #1BB3B2

    &__input
      outline: none
      width: 100%
      border: none
      font-weight: 300
      font-size: 18px
      line-height: 120%
      color: #000
      padding: 9px 0 8px
      font-family: Roboto

      &::placeholder
        font-weight: 300
        font-size: 18px
        line-height: 120%
        color: #B0B0B0

    &__icon
      width: 40px
      height: 40px
      position: absolute
      right: 0
      top: 0
      bottom: 0
      margin: auto
      display: flex
      justify-content: center
      align-items: center

      .cross
        width: 10px
        height: 10px

#app .select-search__input[disabled]
  background: unset!important
  opacity: 0.3
</style>
