import axios from 'axios';

import config from '../config';

const API = axios.create({
    baseURL: config.API_URL,
    headers: {
        "Content-Type": 'application/json'
    }
});

const fetchAllData = (old = 18) => {
    return API.get(`/dictionaries?age=${old}`)
}

const fetchWithParams = (params) => {
    return API.get(`/dictionaries?${params}`)
}

const fetchTimeSlots = (params) => {
    return API.get(`/timeslots?${params}`)
}

const createAppointmentSession = (params) => {
    return API.post('/appointment/create', params)
}

const fetchAppBtns = () => {
    return API({url: `/api/settings`, baseURL: ''})
}

export {
    fetchAllData,
    fetchWithParams,
    fetchTimeSlots,
    createAppointmentSession,
    fetchAppBtns
}

export default API;