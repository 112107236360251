<template>
  <div>
    <datepicker :value="value"
                :inline="true"
                :disabled-dates="getDisabledDays()"
                @selected="onselect"
                :language="locale"
                :openDate="openDate"
                :monday-first="true">
    </datepicker>
  </div>
</template>
<script>

import Datepicker from 'vuejs-datepicker';
import {ru} from 'vuejs-datepicker/dist/locale'
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export default {
  components: {
    Datepicker
  },
  props: ['dates', 'onselect', 'value'],
  data(){
    return {
      locale: ru,
      openDate: null
    }
  },
  watch: {
    dates: function () {
      this.changeToNextMonth()
    }
  },
  methods: {
    getDisabledDays(){
      if(!this.dates || !this.dates.length) {
        return {
          days: [0, 1, 2, 3, 4, 5, 6]
        }
      }
      const self = this;
      this.dates.map(date => [
          new Date(date).getFullYear(),
          new Date(date).getMonth() + 1,
          new Date(date).getDate()
        ]
      );
      return {
        customPredictor: function(date) {
          const calendarDates = new Date(date);
          return !self.dates.some(d => (
              calendarDates.getFullYear() === new Date(d).getFullYear() &&
              calendarDates.getMonth() === new Date(d).getMonth() &&
              calendarDates.getDate() === new Date(d).getDate()
            )
          )
        }
      }
    },
    changeToNextMonth() {
      if(this.dates === undefined){
        return
      }
      let moments = this.dates.map(d => moment(d));
      let now = moment();
      let endOfMonth = moment().endOf('month');
      let range = moment().range(now, endOfMonth);
      let nextMonthFirstDay = new moment().add(1, 'months').startOf('month');
      let nextMonthLastDay = new moment().add(1, 'months').endOf('month');
      let rangeNext = moment().range(nextMonthFirstDay, nextMonthLastDay);

      for (let date of moments) {
        if (range.contains(date)) {
          break;
        } else if (!range.contains(date) && rangeNext.contains(date)) {
          this.openDate = nextMonthFirstDay.toDate();
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .info-about-disabled
    font-size: 14px
    margin-top: 20px

  ::v-deep
    .vdp-datepicker
      &__calendar
        header
          margin: 0 0 30px
          height: 41px
          border-bottom: 1px solid #B0B0B0

  @media screen and (max-width: 480px)
    .vdp-datepicker
      margin-left: -10px
</style>


