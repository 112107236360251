<template>
  <div class="list">
    <div v-if="data.length">

      <div class="list__flex">
        <div class="list__item"
              v-for="item in data"
              :key="item.id"
              :class="{ active: (selectedTimeSlot && selectedTimeSlot.time) == item.time }"
              @click="selectTimeSlot(item)">
          <div class="list__circle"></div>
          <div class="list__value">{{ formatTime(item.time) }}</div>
        </div>
      </div>

    </div>

    <div class="list__empty" v-else>Данные о времени не найдены. Измените или дополните ваш запрос.</div>

  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['data', 'selectTimeSlot', 'selectedTimeSlot'],
  data () {
    return {
      
    }
  },
  methods: {
    formatTime: function(time){
      return time ? moment(time).format('H:mm') : ''
    },
  }
}
</script>

<style scoped lang="sass">
  .list
    margin: 26px 0 0
    padding: 0 8px
    width: 300px

    &__flex
      display: flex
      flex-wrap: wrap
      margin: 0 -8px

    &__item
      width: calc(25% - 8px)
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03)
      border: 1px solid rgba(222, 222, 222, 0.5)
      background: #fff
      color: #000000
      padding: 12px 0
      text-align: center
      border-radius: 5px
      margin: 0 4px 8px
      transition: .3s
      font-weight: 500
      cursor: pointer
      font-family: Roboto Condensed
      font-size: 15px
      line-height: 100%
      &:hover, &.active
        color: #139594
        border-color: #139594

    &__empty
      font-size: 14px
      line-height: 1.35
      
</style>
