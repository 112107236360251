<template>
  <div class="popup" v-if="message.length">
    <div class="popup__inner">
      <div class="popup__message-list">
        <div v-for="(alert, index) in message"
            :key="index"
            class="popup__message-item">
            <div class="popup__icon">
              <img src="`../../public/img/appointment-widget-images/attention-icon.svg`" alt="">
            </div>
            <div class="popup__message">{{alert}}</div>
        </div>
      </div>
      <div class="popup__btn button" @click="onAccept">Ознакомлен</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message', 'onAccept'],
  data(){
    return {

    }
  }
}
</script>

<style scoped lang="sass">
  .popup
    position: fixed
    display: flex
    align-items: center
    justify-content: center
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 99
    background: rgba(#000, .15)

    &__inner
      display: flex
      align-items: center
      max-width: 700px
      margin: 0 auto
      width: 100%
      box-shadow: 5px 8px 70px rgba(0, 0, 0, 0.06)
      background: #ffedc9
      padding: 45px

    &__icon
      margin-right: 30px

    &__message
      font-size: 15px
      font-weight: 700
      margin-right: 50px
      margin-top: 0
      margin-bottom: 0

      &-item
        display: flex
        margin-bottom: 25px
        &:last-child
          margin-bottom: 0

    &__btn.button
      background: #fff!important
      color: #000!important
      font-size: 15px!important
      font-weight: 500!important
      height: 48px!important

    @media screen and (max-width: 560px)
      &__inner
        display: block
        padding: 35px 25px

      &__message
        margin-right: 0
        margin-bottom: 15px


</style>
