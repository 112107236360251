<template>
  <div class="thank-popup">
    <div class="thank-popup__inner">
      <div class="thank-popup__content">
          <div class="thank-popup__title">Вы успешно записаны.</div>
          <table>
            <tr>
              <td>Дата / Время</td>
              <td>{{ formatDateTime(data.date, 'date') }} в {{ formatDateTime(data.date, 'timeslot') }}</td>
            </tr>
            <tr>
              <td>ФИО врача</td>
              <td>{{data.doctor.name}}</td>
            </tr>
            <tr>
              <td>Услуга</td>
              <td>{{data.service.name}}</td>
            </tr>
            <tr>
              <td>Клиника</td>
              <td>{{data.clinic.name}}(м.{{data.clinic.metro}})</td>
            </tr>

          </table>
          <button @click="redirectPage" class="thank-popup__btn">На главную</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['data', 'timeslot', ''],
  methods: {
    formatDateTime: function(time, type){
      moment.locale('ru');
      if(!time) return '';
      if(type == 'date'){
        return moment(time).format('L');
      }
      if(type == 'timeslot'){
        return moment(time).format('LT')
      }
    },
    redirectPage() {
      document.location.href="/";
    }
  }
}
</script>

<style lang="sass" scoped>
  .thank-popup
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    background: rgba(#000, .4)
    z-index: 9

    &__title
      font-family: Roboto Condensed
      font-weight: bold
      font-size: 24px
      line-height: 26px
      text-transform: uppercase
      margin: 0 0 50px

      @media all and (max-width: 768px)
        font-size: 20px
        margin: 0 0 20px

    table
      margin: 0 0 32px
      @media all and (max-width: 768px)
        margin: 0 0 15px
      td
        padding-right: 15px
        padding-bottom: 10px
        line-height: 1.3
        &:first-child
          min-width: 145px
          @media all and (max-width: 768px)
            min-width: 120px
        &:last-child
          padding-right: 0
          font-weight: bold

    &__inner
      position: relative
      display: flex
      flex-direction: column
      align-items: center
      max-width: 600px
      width: 100%

      @media all and (max-height: 740px)
          transform: translate(0,12vh)

      @media all and (max-width: 768px)
          transform: unset

    &__btn
      text-align: center
      font-size: 14px
      width: 160px
      height: 48px
      border-radius: 4px
      color: #222
      border: 1px solid #1BB3B2
      background-color: transparent
      transition: 0.3s ease-in-out
      cursor: pointer

      &:hover
        background-color: #1BB3B2
        color: #fff

    &__content
      background: #fff
      margin: 0 auto
      padding: 45px 62px
      background: #fff
      width: 100%
      z-index: 15
      color: #2E393D
      font-size: 15px

      @media all and (max-width: 768px)
          padding: 20px

    &__letter
      position: absolute
      bottom: 0
      z-index: 15
</style>
