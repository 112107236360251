<template>
  <div class="popup">
    <div class="popup__inner">
      <div class="popup__close">
        <img src="../../public/img/appointment-widget-images/cross.svg" @click="closePopup">
      </div>
      <div v-if="messageType != 'notSuitableAge'" class="popup__title">Записаться не получилось, попробуйте еще раз или запишитесь по телефону <a href="tel:+7(495)660-30-90" class="link">+7(495)660-30-90</a></div>
      <div v-if="messageType == 'notSuitableAge'" class="popup__title">К сожалению, выбранная услуга не оказывается данным врачом для указанного возраста. Для уточнения информации свяжитесь, пожалуйста, с нашим колл центром по телефону <a href="tel:+7(495)660-30-90" class="link">+7(495)660-30-90</a></div>
      <div class="popup__text">{{message}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['message', 'setErrorMessage', 'messageType'],
    methods: {
      closePopup () {
        this.setErrorMessage(null, true);
      }
    }
  }
</script>

<style lang="sass" scoped>
  .popup
    position: fixed
    display: flex
    align-items: center
    justify-content: center
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 999
    background: none!important
    &__title
      text-align: center
      font-size: 18px
      line-height: 1.4
      font-weight: bold
      margin-bottom: 25px
      a
        color: #1bb3b2
        text-decoration: none
    &__text
      color: red
      text-align: center

    &__inner
      max-width: 450px
      position: relative
      margin: 0 auto
      width: 100%
      background: #fff
      padding: 45px
      box-shadow: 0px 0px 35px 0px rgba(0,0,0,0.2)

    &__close
      position: absolute
      top: 20px
      right: 20px
      img
        width: 16px
        height: 16px
        cursor: pointer
</style>


