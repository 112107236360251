<template>
  <div id="app">
    <div class="app-widget">
      <ErrorPopup v-if="errorMessage || errorMessageType"
                  :message="errorMessage"
                  :messageType="errorMessageType"
                  :setErrorMessage="setErrorMessage">
      </ErrorPopup>
      <Preloader v-if="isLoading"></Preloader>
      <Popup :message="alertsFromResponse.messages" :onAccept="onAcceptAlerts"></Popup>

      <ThankPopup v-if="isCreateAppointment"
                  :data="createdAppointmentResponseData"
                  :doctor="createdAppointmentResponseData.doctor.name"
                  :date="createdAppointmentResponseData.date">
      </ThankPopup>

      <PhoneConfirmPopup v-if="showPhoneConfirmPopup"
                         ref="phoneConfirmPopupRef"
                         :callbackAfterCreateAppointment="callbackAfterCreateAppointment"
                         :userData="userData"
                         :selectedData="selectedData"
                         :setConfirmCode="setConfirmCode"
                         :phoneConfirmState="phoneConfirmState"
                         :setStatePhoneConfirmRequestSend="setStatePhoneConfirmRequestSend"
                         :validatePhoneFormat="validatePhoneFormat"
                         :preloaderToggle="preloaderToggle"
                         :setErrorMessage="setErrorMessage"
                         :closeMethod="closePhoneConfirmPopup"
                         :setPhoneValue="setPhoneValue">
      </PhoneConfirmPopup>

      <div v-if="showChangeBirthdatePopup" class="birthdate-popup">
        <div class="birthdate-popup__inner">
          <div class="birthdate-popup__close">
            <img src="../../public/img/appointment-widget-images//cross.svg" @click="toggleChangeBirthdatePopup">
          </div>
          <div class="birthdate-popup__text">После изменения даты рождения выбранные параметры для записи могут быть сброшены</div>
          <div class="birthdate-popup__btn">
            <button class="button" @click="updateCurrentBirthDate">Изменить</button>
          </div>
        </div>
      </div>

      <div v-if="showNoDatePopup" class="birthdate-popup">
        <div class="birthdate-popup__inner">
          <div class="birthdate-popup__close">
            <img src="../../public/img/appointment-widget-images//cross.svg" @click="toggleNoDatePopup">
          </div>
          <div class="birthdate-popup__text">Свободные даты не найдены или онлайн-запись недоступна. Попробуйте записаться по телефону</div>
          <a href="tel:+7(495)660-30-90" class="birthdate-popup__phone">+7(495)660-30-90</a>
        </div>
      </div>

      <div class="age-picker" v-if="!ageIsPicket">
        <div class="age-picker__inner">
          <div class="age-picker__title">Для записи укажите дату рождения пациента</div>
            <AgeInput
              :birthDateState="birthDateState"
              :setStateBirthDate="setStateBirthDate"
              button="Ok"
              :inPopup="true"
            ></AgeInput>
            <div class="appTxt">Или скачайте наше приложение, чтобы записаться</div>
            <div class="appBtns">
                <a :href="appstoreLink" target="_blank">
                  <img src="../../public/img/appointment-widget-images/appstore.png" />
                </a>

                <a class="last" :href="gplayLink" target="_blank">
                  <img src="../../public/img/appointment-widget-images/googleplay.png" />
                </a>
            </div>
          </div>
      </div>

      <div class="wrapper">
        <div class="app-widget__inner">
          <div class="app-widget__head">
            <div class="app-widget__title">Запись на приём</div>
            <div class="app-widget__subtitle" v-html="appointmentTitleContent"></div>
            <div class="reset-all" @click="resetAll(null, true)">Сбросить все</div>
          </div>
          <div class="app-widget__body">
            <div class="body-selection">

              <div class="body-selection__item">
                <div class="body-selection__label">Клиника
                  <div v-if="selectedData.clinics" class="body-selection__clear" @click="clearSelectedOption('clinics')">
                    <img src="../../public/img/appointment-widget-images/cross.svg" alt="">
                  </div>
                </div>
                <SelectionList :data="getBottomFilterList('clinics')"
                               type="clinics"
                               :onSelect="onChangeSelect"
                               :selectedData="selectedData.clinics"
                               :clearSelected="clearSelectedOption">
                </SelectionList>
              </div>

              <div class="body-selection__item" v-if="showFieldRules('services')">
                <div class="body-selection__label">Услуги
                  <div v-if="selectedData.services" class="body-selection__clear" @click="clearSelectedOption('services')">
                    <img src="../../public/img/appointment-widget-images/cross.svg" alt="">
                  </div>
                </div>
                <SelectionList :data="getBottomFilterList('services')"
                               type="services"
                               :onSelect="onChangeSelect"
                               :selectedData="selectedData.services"
                               :clearSelected="clearSelectedOption">
                </SelectionList>
              </div>

              <div class="body-selection__item" v-if="showFieldRules('doctors')">
                <div class="body-selection__label">Врач
                  <div v-if="selectedData.doctors" class="body-selection__clear" @click="clearSelectedOption('doctors')">
                    <img src="../../public/img/appointment-widget-images/cross.svg" alt="">
                  </div>
                </div>
                <SelectionList :data="getBottomFilterList('doctors')"
                               type="doctors"
                               :onSelect="onChangeSelect"
                               :selectedData="selectedData.doctors"
                               :clearSelected="clearSelectedOption">
                </SelectionList>
              </div>

              <div class="body-selection__item">
                <div class="body-selection__label">дата и время</div>
                <DatePicker :dates="getOptions('dates')"
                            type="date"
                            :onselect="selectDatePicker"
                            :value="selectedData.date"
                            >
                </DatePicker>
                <TimeList type="timeslot"
                          :data="sortedDataByParams.timeslot"
                          :selectTimeSlot="selectTimeSlot"
                          :selectedTimeSlot="selectedData.timeslot">
                </TimeList>
              </div>

            </div>
          </div>
          <div class="app-widget__foot" :class="{ 'is-hide': !isReadyToFillPersonalForm() }">
            <PersonDataForm ref="personDataFormRef"
                            :userData="userData"
                            :setStateTypeOfPatient="setStateTypeOfPatient"
                            :birthDateState="birthDateState"
                            :setStateBirthDate="setStateBirthDate"
                            :toggleChangeBirthdatePopup="toggleChangeBirthdatePopup">
            </PersonDataForm>
            <div class="app-widget__submit">
              <button class="button"
                      @click="createAppointmentSession">
                      Записаться
              </button>
              <div class="terms">Нажимая на кнопку "Записаться" вы соглашаетесь на обработку персональных данных в соответствии с Условиями</div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
/* eslint-disable */

import API, { fetchAllData, fetchWithParams, fetchTimeSlots, fetchAppBtns } from '../api';
import Select from '@/components/Select';
import SelectionList from '@/components/SelectionList';
import TimeList from '@/components/TimeList';
import PersonDataForm from '@/components/PersonDataForm';
import DatePicker from '@/components/DatePicker';
import Preloader from '@/components/Preloader';
import Popup from '@/components/Popup';
import { getErrorMessage, compareDates } from '../utils';
import ThankPopup from '@/components/ThankPopup';
import ErrorPopup from '@/components/ErrorPopup';

import queryString from 'query-string';
import moment from 'moment';
import Agepicker from 'vuejs-datepicker';
import AgeInput from '@/components/AgeInput';
import PhoneConfirmPopup from '@/components/PhoneConfirmPopup';

import {ru} from 'vuejs-datepicker/dist/locale'

export default {
  name: 'home',
  components: {
    Select,
    SelectionList,
    TimeList,
    PersonDataForm,
    DatePicker,
    Agepicker,
    Preloader,
    Popup,
    ThankPopup,
    AgeInput,
    PhoneConfirmPopup,
    ErrorPopup
  },
  data(){
    return {
      generalDataIsLoaded: false,
      errorBirthDateFormat: '',
      locale: ru,
      ageIsPicket: false,
      isLoading: false,
      isCreateAppointment: false,
      createdAppointmentResponseData: {},
      lastFilterByQuery: '',
      firstFilterParam: 'clinics',
      showPhoneConfirmPopup: false,
      showChangeBirthdatePopup: false,
      showNoDatePopup: false,
      errorMessage: '',
      errorMessageType: null,
      appointmentTitleContent: '',
      prevRoute: '',
      activeRoute: '',

      birthDateState: {
        ageValue: '',
        fullDateWithMask: '',
        fullDateWithoutMask: '',
        formattedDate: '',
        previousBirthDate: '',
        previousAgeValue: '',
      },

      phoneConfirmState: {
        phoneFormatIsValid: false,
        isRequestSend: false,
        confirmCode: '',
      },

      alertsFromResponse: {
        messages: [],
        isAcceptedAlert: false
      },

      userData: {
        patientName: '',
        patientSurname: '',
        patientPatronymic: '',
        patientBirthDate: '',
        phone: '',
        comment: '',
        jwtToken: '',
        isPhoneConfirmed: false,
        anotherPatient: false,
        additionalInfo: '',
        touchedValidateField: false,
      },

      allDictionaryData: {
        clinics: [],
        doctors: [],
        services: [],
        dates: [],
        timeslot: [],
      },

      bottomFilterList: {
        clinics: [],
        doctors: [],
        services: [],
        dates: [],
        timeslot: [],
      },

      sortedDataByParams: {
        clinics: [],
        doctors: [],
        services: [],
        dates: [],
        timeslot: [],
      },

      selectedData: {
        clinics: null,
        doctors: null,
        services: null,
        date: null,
        timeslot: null,
      },

      appstoreLink: 'http://appstore.com',
      gplayLink: 'http://play.google.com',
    }
  },
  watch: {
    $route (to, from){
      this.activeRoute = to.query;
      this.prevRoute = from.query;
    },
    showPhoneConfirmPopup(v_new, v_old) {
      if (v_new===true) {
        let th = this;

        setTimeout(()=>{
          th.$refs.phoneConfirmPopupRef.setFocus();
        },400);
      }
      ///console.log(v_old, v_new);
    }
  },
  mounted(){
    this.getAppointmentTitleContent();
    this.fetchDataFromApi();
    fetchAppBtns().then((xhr)=>{
        this.appstoreLink = xhr.data.appstore;
        this.gplayLink = xhr.data.gplay;
    })

  },
  methods: {
    getAppointmentTitleContent(){
      this.appointmentTitleContent = window.appointmentTitleContent
        ? window.appointmentTitleContent
        : '';
    },

    closePhoneConfirmPopup(){
      this.showPhoneConfirmPopup = false;
      this.phoneConfirmState.isRequestSend = false;
      this.phoneConfirmState.confirmCode = '';
      this.userData.phone = '';
      this.$refs.phoneConfirmPopupRef.stopCounter();
    },

    setErrorMessage(err, remove){
      if(remove){
        this.errorMessage = '';
        this.errorMessageType = '';
        return;
      }
      console.error('Error', err);
      const message = err.response ? err.response.data.message : 'Что-то пошло не так';
      this.errorMessage = message;
    },

    toggleChangeBirthdatePopup(){
      console.log(this.errorBirthDateFormat);
      
      if (!this.errorBirthDateFormat)
          this.showChangeBirthdatePopup = !this.showChangeBirthdatePopup
    },

    toggleNoDatePopup(){
      this.showNoDatePopup = !this.showNoDatePopup
    },

    updateCurrentBirthDate(){
      console.log('updateCurrentBirthDate');

      this.toggleChangeBirthdatePopup();
      this.setStateBirthDate('ageValue', null, "");
      this.setStateBirthDate('formattedDate', this.birthDateState.fullDateWithoutMask);
    },

    setStateBirthDate(field, value, errorText=false) {
      // console.log('setStateBirthDate',field,value,errorText);

      if (errorText)
        this.errorBirthDateFormat = errorText;

      if(field === 'formattedDate') {
        this.birthDateState.formattedDate = moment(value,"DDMMYYYY").toISOString();
        this.userData.patientBirthDate = this.birthDateState.formattedDate;
        return
      };

      if(field === 'previousBirthDate'){
        this.birthDateState.previousBirthDate = this.birthDateState.fullDateWithMask;
        return
      };

      if(field === 'ageValue') {
        const date = this.birthDateState.formattedDate;
        const ageValue = this.calcBirthDateAgeValue(date, errorText);
        if(!ageValue){
          return;
        }
        this.birthDateState.ageValue = ageValue;
        this.pushQueryToUrl({ age: ageValue });
        this.ageIsPicket = true;
        this.generalDataIsLoaded = false;
        this.fetchDataFromApi();
        return;
      };

      if(field === 'previousAgeValue') {
        const date = this.birthDateState.previousBirthDate.replace(/\D/g,'');
        const ageValue = this.calcBirthDateAgeValue(date, errorText);
        if(!ageValue){
          return;
        }

        this.birthDateState.previousAgeValue = ageValue;
        this.birthDateState.ageValue = ageValue;
        this.pushQueryToUrl({ age: ageValue });
        this.ageIsPicket = true;
        return;
      }
      if(field === 'fullDateWithMask') {
        this.birthDateState.fullDateWithMask = value;
      }

      if(field === 'fullDateWithoutMask'){
        this.birthDateState.fullDateWithoutMask = value.replace(/\D/g,'');
      }
    },

    preloaderToggle(){
      this.isLoading = !this.isLoading;
    },
    callbackAfterCreateAppointment(data){
      this.isLoading = false;
      this.showPhoneConfirmPopup = false;
      this.$refs.phoneConfirmPopupRef.stopCounter();
      this.setStatePhoneConfirmed(true);
      this.isCreateAppointment = true;
      this.createdAppointmentResponseData = data;
      //Вызываем внешний колбэк с сайта
      if(window.createAppointmentCallback){
        createAppointmentCallback(data);
      }
    },

    setStatePhoneConfirmRequestSend(state){
      this.phoneConfirmState.isRequestSend = state
    },

    setConfirmCode(code){
      this.phoneConfirmState.confirmCode = code
    },

    getBottomFilterList(type){
      if(type === 'date') return this.sortedDataByParams.date;
      if(type === 'timeslot') return this.sortedDataByParams.timeslot;

      if(this.lastFilterByQuery !== type){
        this.bottomFilterList[type] = this.sortedDataByParams[type];
      }
      else if(!this.bottomFilterList[type].length){
        this.bottomFilterList[type] = this.sortedDataByParams[type];
      }
      else if (this.bottomFilterList[type].length !== this.sortedDataByParams[type].length) {
        this.bottomFilterList[type] = this.sortedDataByParams[type];
      }

      return this.isFetchWithParams()
            ? this.bottomFilterList[type]
            : this.allDictionaryData[type];
    },

    resetAll(event, fullReset = false){
      const query = this.$route.query;
      this.$router.push({ path: this.$route.params[0], query: {} })
      this.firstFilterParam = 'clinics';
      this.lastFilterByQuery = '';
      this.generalDataIsLoaded = false;
      this.userData.touchedValidateField = false;
      this.isCreateAppointment = false;
      this.selectedData = {
        clinics: null,
        doctors: null,
        services: null,
        date: null,
        timeslot: null,
      };
      this.sortedDataByParams = {
        clinics: [],
        doctors: [],
        services: [],
        dates: [],
        timeslot: [],
      };
      this.bottomFilterList = {
        clinics: [],
        doctors: [],
        services: [],
        dates: [],
        timeslot: [],
      };
      this.userData = {
        ...this.userData,
        jwtToken: '',
        isPhoneConfirmed: false,
        anotherPatient: false,
        additionalInfo: '',
        phone: '',
      };
      this.phoneConfirmState.phoneFormatIsValid = false;

      if(fullReset){
        this.userData.patientBirthDate = '';
        this.birthDateState.ageValue = '';
        this.birthDateState.fullDateWithMask = '';
        this.birthDateState.fullDateWithoutMask = '';
        this.birthDateState.previousBirthDate = '';
        this.birthDateState.previousAgeValue = '';
        this.ageIsPicket = false;
        this.userData.patientName = '';
        this.userData.patientSurname = '';
        this.userData.patientPatronymic = '';
        this.userData.comment = '';
      }
      else {
        this.$router.push({ path: this.$route.params[0], query: { age: this.birthDateState.ageValue} });
        this.fetchDataFromApi();
      }
    },

    setStatePhoneConfirmed(state){
      this.userData.isPhoneConfirmed = state
    },

    setStateTypeOfPatient(state){
      this.userData.anotherPatient = state
    },

    calcBirthDateAgeValue(date, errorText){
      if (!date) return false;

      let resultAgeValue;
      const birthDateYear = moment(date);
      const today =  moment().subtract(2,'hours');
      let calcAgeYear = 0;
      if(!birthDateYear.isAfter(today, 'day')) {
        const formatCalcHour = Math.abs(moment.duration(birthDateYear.diff(today)).asHours());
        const formatCalcAge = Math.abs(moment.duration(birthDateYear.diff(today)).asYears());
        if((formatCalcHour > 0 && formatCalcHour < 24) && formatCalcAge > 0) {
          calcAgeYear = 1;
        } else {
          calcAgeYear = Math.abs(formatCalcAge) < 1 ? Math.round(Math.abs(formatCalcAge) + 0.6) : Math.floor(Math.abs(formatCalcAge))
        }
      }
      if(calcAgeYear <= 0){
        resultAgeValue = 0
      }
      else {
        resultAgeValue = calcAgeYear
      }
      return resultAgeValue;
    },
    customFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    fetchDataFromApi() {
      const query = this.$route.query;
      if(!this.birthDateState.ageValue) return;
      // если это первый запрос и без параметров, то запрашиваем dictionary( список всего )
      if (!this.generalDataIsLoaded && !this.isFetchWithParams()) {
        this.isLoading = true;
        fetchAllData(this.birthDateState.ageValue)
          .then(this.callbackAllDictionaryResponse)
          .catch(err => {
            this.isLoading = false;
            this.setErrorMessage(err);
          })
      }else if( this.generalDataIsLoaded && this.isFetchWithParams()){
      // если у нас уже имеется dictionary список, то делаем только запрос на фильтр
        this.fetchDataWithParams()
      }else if(!this.generalDataIsLoaded && this.isFetchWithParams()) {
      // если у нас не имеется dictionary список, то делаем 2 запроса паралелльно
        this.isLoading = true;
        Promise.all([
          fetchAllData(this.birthDateState.ageValue),
          fetchWithParams(queryString.stringify(query))
        ])
        .then(([globalSearchRes, filterSearchRes]) => {
            this.callbackAllDictionaryResponse(globalSearchRes);
            this.callbackFilteredResponse(filterSearchRes);
        })
        .catch(err => {
          this.isLoading = false;
          this.setErrorMessage(err);
        })
      }

    },

    callbackAllDictionaryResponse(res){
      this.isLoading = false;
      this.generalDataIsLoaded = true;

      this.allDictionaryData.clinics = res.data.clinics
      this.allDictionaryData.doctors = res.data.doctors
      this.allDictionaryData.services = res.data.services
      this.allDictionaryData.dates = res.data.dates
    },

    isRequestNotSuitableAge(res){
      return res.data.clinics.length == 0 &&
             res.data.doctors.length == 0 &&
             res.data.services.length == 0 &&
             res.data.dates.length == 0
    },

    callbackFilteredResponse(res){
      const query = this.$route.query;
      let needExtraFetch = false;

      this.isLoading = false;

      if(this.isRequestNotSuitableAge(res)){
        this.errorMessageType = 'notSuitableAge';

        if(this.birthDateState.previousBirthDate){
          this.setStateBirthDate('fullDateWithMask', this.birthDateState.previousBirthDate);
          this.setStateBirthDate('fullDateWithoutMask', this.birthDateState.fullDateWithMask);
          this.setStateBirthDate('previousAgeValue');
          this.setStateBirthDate('formattedDate', this.birthDateState.fullDateWithoutMask);
        }
        return;
      }

      this.setStateBirthDate('previousBirthDate');
      this.setStateBirthDate('previousAgeValue');

      this.sortedDataByParams.clinics = res.data.clinics;
      this.sortedDataByParams.doctors = res.data.doctors;
      this.sortedDataByParams.services = res.data.services;
      this.sortedDataByParams.dates = res.data.dates;

      if(query.clinicId) {
        this.selectedData.clinics = this.filteredElementById(query.clinicId, 'clinics');
      }
      if(query.serviceId) {
        this.selectedData.services = this.filteredElementById(query.serviceId, 'services');
      }
      if(query.doctorId) {
        this.selectedData.doctors = this.filteredElementById(query.doctorId, 'doctors');
      }
      if(query.date) {
        // this.selectedData.dates = this.filteredElementById(query.date, 'dates');
        this.selectedData.dates = query.date;
      }

      if(this.sortedDataByParams.clinics && this.sortedDataByParams.clinics.length == 1 && !query.clinicId){
        this.pushQueryToUrl({clinicId: this.sortedDataByParams.clinics[0].id})
        needExtraFetch  = true;
      }
      if(this.sortedDataByParams.services && this.sortedDataByParams.services.length == 1 && !query.serviceId ){
        this.pushQueryToUrl({serviceId: this.sortedDataByParams.services[0].id})
        needExtraFetch  = true;
      }

      // if(this.sortedDataByParams.doctors && this.sortedDataByParams.doctors.length == 1 && !query.doctorId ){
      //   this.pushQueryToUrl({doctorId: this.sortedDataByParams.doctors[0].id})
      //   needExtraFetch  = true;
      // }

      // если все параметры кроме таймслота выбраны, то запрашивать будем только таймслоты
      if( this.readyToFetchTimeSlot() ) {
        this.fetchTimeSlots();
      }

      if(res.data.dates === undefined && (this.selectedData.doctors || this.selectedData.services)) {
        this.showNoDatePopup = true;
      }

      if(needExtraFetch){
        needExtraFetch = false;
        this.fetchDataWithParams();
      }
    },

    fetchDataWithParams: function() {
      this.isLoading = true;
      const query = this.$route.query;
      fetchWithParams(queryString.stringify(query))
        .then(this.callbackFilteredResponse)
        .catch(err => {
          this.isLoading = false;
          this.setErrorMessage(err);
        })
    },

    fetchTimeSlots: function(){
      this.isLoading = true;
      const query = this.$route.query;
      fetchTimeSlots(queryString.stringify(query))
        .then(res => {
          this.isLoading = false;
          this.sortedDataByParams.timeslot = ( res.data[0] && res.data[0].times ) ? res.data[0].times : [];
        })
        .catch(err => {
          this.isLoading = false;
          this.setErrorMessage(err);
        })
    },

    filteredElementById(id, property){
      return this.allDictionaryData[property].filter(item => item.id == id)
    },

    getOptions(property){
      return this.isFetchWithParams()
        ? this.sortedDataByParams[property]
        : this.allDictionaryData[property];
    },

    isDisableDatepicker(){
      return !this.selectedData.clinics || !this.selectedData.services || !this.selectedData.doctors
    },

    onChangeSelect: function({ field, searchType }, value){

      switch (field) {
        case 'clinics':
          this.selectedData.clinics = value;
          this.lastFilterByQuery = 'clinics';
          if( searchType == 'global' ){
            this.firstFilterParam = 'clinics';
          }
          if( searchType == 'global' && !this.sortedDataByParams.clinics.some(clinic => clinic.id == value.id) ){
            this.prepareForGlobalSearch({ clinicId: value.id });
          } else {
            this.pushQueryToUrl({ clinicId: value.id });
          }
          break;
        case 'services':
          this.selectedData.services = value;
          this.lastFilterByQuery = 'services';
          if( searchType == 'global' ){
            this.firstFilterParam = 'services';
          }
          if(searchType == 'global' && !this.sortedDataByParams.services.some(service => service.id == value.id)){
            this.prepareForGlobalSearch({ serviceId: value.id })
          } else {
            this.pushQueryToUrl({ serviceId: value.id });
          }
          break;
        case 'doctors':
          // if(this.selectedData.clinics){
          //   this.selectedData.clinics = "";
          //   const currentQuery = this.$route.query;
          //   delete currentQuery.clinicId;
          //   this.$router.push({ path: this.$route.params[0], query: {} });
          //   this.$router.push({ path: this.$route.params[0], query: currentQuery });
          // }

          this.selectedData.doctors = value;
          this.lastFilterByQuery = 'doctors';
          if( searchType == 'global' ){
            this.firstFilterParam = 'doctors'
          }
          if(searchType == 'global' && !this.sortedDataByParams.doctors.some(doctor => doctor.id == value.id)){
            this.prepareForGlobalSearch({ doctorId: value.id })
          } else {
            this.pushQueryToUrl({ doctorId: value.id });
          }
          break;
        case 'date':
          this.selectedData.date = value;
          this.pushQueryToUrl({ date: value.id });
          break;
        case 'timeslot':
          this.selectedData.timeslot = value;
          this.pushQueryToUrl({ clinicId: value.id });
          break;
        default:
          break;
      }

      this.fetchDataFromApi();
    },

    pushQueryToUrl(query){
      const currentQuery = this.$route.query;
      this.$router.push({ path: this.$route.params[0], query: { ...currentQuery, ...query } })
    },
    prepareForGlobalSearch(newQueryParam){
      this.$router.push({ path: this.$route.params[0], query: { age: this.birthDateState.ageValue, ...newQueryParam } });
      this.selectedData = {
        clinics: null,
        doctors: null,
        services: null,
        date: null,
        timeslot: null,
      };
      this.sortedDataByParams = {
        clinics: [],
        doctors: [],
        services: [],
        dates: [],
        timeslot: [],
      };
    },

    selectDatePicker(date){
      const query = this.$route.query;
      this.selectedData.timeslot = null;
      if(compareDates(this.selectedData.date, date)){
        this.resetDateChoose();
        this.resetTimeSlotChoose();
      }
      else {
        this.pushQueryToUrl({ date: moment(date).toISOString() });
        this.selectedData.date = date;
      }
      this.fetchDataFromApi();
    },

    isFetchWithParams: function(){
      const query = this.$route.query;
      return ( query.clinicId || query.serviceId || query.doctorId || query.date )
    },

    readyToFetchTimeSlot(){
      const { clinics, services, date, doctors } = this.selectedData;
      const query = this.$route.query;

      return ( query.age && query.serviceId && query.doctorId && query.clinicId && query.date ) &&
             ( clinics && services && date && doctors );
    },

    resetDateChoose(){
      this.resetTimeSlotChoose();
      const query = this.$route.query;
      this.selectedData.date = null;
      delete query.date;
      this.$router.push({ path: this.$route.params[0], query: {} })
      this.$router.push({ path: this.$route.params[0], query });
    },

    resetTimeSlotChoose(){
      const query = this.$route.query;
      this.selectedData.timeslot = null;
      this.sortedDataByParams.timeslot = [];
      delete query.timeSlot;
      this.$router.push({ path: this.$route.params[0], query: {} })
      this.$router.push({ path: this.$route.params[0], query })
    },

    selectTimeSlot(timeslot){
      const query = this.$route.query;

      if(this.selectedData.timeslot == timeslot){
        this.resetTimeSlotChoose();
        this.fetchDataFromApi();
        return;
      }

      this.selectedData.timeslot = timeslot;
      query.timeSlot = timeslot;
      this.$router.push({ path: this.$route.params[0], query: {} })
      this.$router.push({ path: this.$route.params[0], query })
    },

    formatTime: function(time){
      return time ? moment(time).format('H:mm') : ''
    },

    showFieldRules(field){
      if(window.innerWidth <= 700){
        return true
      }
      switch (field) {
        case 'clinics':
          return this.firstFilterParam == 'services' || this.firstFilterParam == 'doctors' ||
              (this.sortedDataByParams.clinics.length > 1 && Array.isArray(this.selectedData.doctors))
          break;
        case 'services':
          return this.firstFilterParam == 'clinics' || this.firstFilterParam == 'doctors' &&
              !(this.sortedDataByParams.clinics.length > 1 && Array.isArray(this.selectedData.doctors))
          break;
        case 'doctors':
          return this.firstFilterParam == 'clinics' || this.firstFilterParam == 'services'
          break;
        default:
          break;
      }
    },

    onAcceptAlerts(){
      this.alertsFromResponse.isAcceptedAlert = true;
      this.alertsFromResponse.messages = [];
      this.showPhoneConfirmPopup = true;
    },

    createAppointmentSession() {

      if(!this.isDisabledSubmitButton()){
        this.userData.touchedValidateField = true;
        return;
      }

      const { clinics, services, doctors, date, timeslot } = this.selectedData;

      const sendData = {
        "clinicId": clinics[0].id,
        "doctorId": doctors[0].id,
        "serviceId": services[0].id,
        "patientName": this.userData.patientName,
        "patientSurname": this.userData.patientSurname,
        "patientPatronymic": this.userData.patientPatronymic,
        "patientBirthDate": moment(this.userData.patientBirthDate).format("YYYY-MM-DD"),
        "anotherPatient": this.userData.anotherPatient,
        "contactPhone": "",
        "comment": this.userData.comment,
        "timeSlot": this.selectedData.timeslot,
        "metrics": [
          {
            "metric": "calltouchSession",
            "value": ""
          }
        ]
      };
      //передаем метрику с колтач из сайта
      sendData.metrics[0].value = window.call_value_1 ? window.call_value_1 : "";

      if(this.userData.anotherPatient) {
        sendData.contactPhone = this.userData.additionalInfo;
      }

      API.post('/appointment', sendData, {
        headers: {'Authorization': `bearer ${this.userData.jwtToken}`}
      }).then(res => {
        this.userData.jwtToken = res.data.jwt;
        if(res.data.alerts.length > 0){
          this.alertsFromResponse.messages = res.data.alerts;
          this.alertsFromResponse.isAcceptedAlert = false;
        } else {
          this.showPhoneConfirmPopup = true;
        }
      }).catch(err => {
        this.setErrorMessage(err);
      })
    },

    deleteSelectedDateAndTime(){
      const query = this.$route.query;
      if (query.date) {
        delete query.date;
        this.selectedData.date = null
      }
      if (query.timeSlot) {
        delete query.timeSlot;
        this.selectedData.timeslot = null
        this.sortedDataByParams.timeslot = [];
      }

      this.$router.push({ path: this.$route.params[0], query: {} });
      this.$router.push({ path: this.$route.params[0], query });
      this.fetchDataWithParams()
    },

    isDisabledSubmitButton(){
      const { clinics, doctors, services, date, timeslot } = this.selectedData;
      const { patientName, patientSurname, patientPatronymic } = this.userData;
      // const { phoneFormatIsValid, isRequestSend, confirmCode } = this.phoneConfirmState;
      return ( patientName && patientSurname && patientPatronymic && clinics && doctors && services && date && timeslot);
    },

    isReadyToFillPersonalForm(){
      const { clinics, doctors, services, date, timeslot } = this.selectedData;
      return ( clinics && doctors && services && date && timeslot);
    },

    clearSelectedOption(type){
      const query = this.$route.query;

      switch (type) {
        case 'clinics':
          this.selectedData.clinics = null;
          this.lastFilterByQuery = 'clinics';
          delete query.clinicId;
          break;
        case 'services':
          this.selectedData.services = null;
          this.lastFilterByQuery = 'services';
          delete query.serviceId;
          break;
        case 'doctors':
          this.selectedData.doctors = null;
          this.lastFilterByQuery = 'doctors';
          delete query.doctorId;
          break;
        default:
          break;
      }

      if(!this.selectedData.clinics && !this.selectedData.services && !this.selectedData.doctors){
        this.firstFilterParam = 'clinics'
      }

      this.$router.push({ path: this.$route.params[0], query: {} });
      this.$router.push({ path: this.$route.params[0], query });

      this.fetchDataWithParams();
    },
    validatePhoneFormat(phone){
      this.phoneConfirmState.phoneFormatIsValid = (phone.replace(/\D/g,'').length == 11)
    },
    setPhoneValue(e) {
      const phone = e.target.value;
      this.validatePhoneFormat(phone);
      this.userData.phone = phone;
    },

  },
  filters: {
    formatDate: function(value){
      return value ? moment(value).format("DD.MM.YYYY") : '';
    }
  }
}
</script>

<style lang="sass">
  body
    padding: 0
    margin: 0
  .wrapper
    max-width: 1400px
    margin: 0 auto

  .app-widget
    position: relative
    *
      box-sizing: border-box

</style>

<style scoped lang="sass">
  
  .appTxt
    display: block
    margin: 40px 0 15px 0
  .appBtns
    display: flex
    justify-content: space-between

    a.last
      display: block
      margin-left: 10px
    
  .app-widget
    margin: 20px 0 45px
    color: #3a3a3a
    font-size: 16px
    font-family: 'Roboto'

    .wrapper
      padding: 0

    .age-picker__error
      color: red
      margin-top: 8px
      font-size: 13px

    .age-picker,
    .success-form-popup
      position: fixed
      display: flex
      justify-content: center
      align-items: center
      top: 0
      right: 0
      left: 0
      bottom: 0
      z-index: 99
      background: rgba(#000, .15)
      &__form
        display: flex
        .button
          margin-left: 10px
      &__inner
        max-width: 400px
        width: 100%
        padding: 50px
        background: #fff
      &__title
        font-size: 21px
        font-weight: 500
        margin-bottom: 25px

    &__inner
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06)
      background-color: #ffffff
      padding: 50px 40px

    &__title
      font-size: 30px
      font-weight: 700
      text-transform: uppercase
      margin: 0 79px 0 0

    &__subtitle
      max-width: 600px
      font-size: 15px
      line-height: 1.5
      a
        color: #1bb3b2
        text-decoration: none

    &__head
      margin-bottom: 70px
      display: flex
      align-items: flex-start

    &__foot
      border-top: 1px solid #14AEAD
      padding-top: 70px
      margin-top: 60px

    &__submit
      display: flex
      align-items: center
      margin-top: 35px

      .button
        margin-right: 80px
        padding-left: 60px!important
        padding-right: 60px!important

      .terms
        color: #a6a6a6
        font-size: 15px

  .success-form-popup
    font-size: 18px
    &__inner
      display: flex
      max-width: 450px
      width: 100%
    .button
      margin-left: 20px

  .body-selection
    display: flex
    &__label
      font-size: 18px
      font-weight: 500
      text-transform: uppercase
      margin-bottom: 25px
      display: flex
    &__clear
      cursor: pointer
      margin: 2px 5px 0 15px
      img
        width: 12px
        height: 12px
        margin-bottom: 3px

    &__item
      width: 25%
      margin-right: 45px
      &:last-child
        margin-right: 0

  .time-to-visit
    display: flex
    flex-direction: column
    align-items: flex-start
    &__label
      font-size: 14px
      font-weight: 500
      margin-bottom: 10px
    &__value
      display: flex
      color: #282828
      font-size: 22px
      font-weight: 300
      border-bottom: 1px dotted #bfbfbf
      padding-bottom: 3px
    &__result
      display: flex
      align-items: center
    &__clear
      cursor: pointer
      margin-left: 12px
      img
        width: 12px
        height: 12px
        margin-bottom: 3px

  .reset-all
    cursor: pointer
    color: #1bb3b2
    border-bottom: 1px solid #1bb3b2
    margin: 0 0 0 auto
    transition: 0.3s

    &:hover
      border-color: transparent

  .birthdate-popup
    position: fixed
    display: flex
    align-items: center
    justify-content: center
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 99
    background: rgba(#000, .15)
    &__close
      position: absolute
      top: 20px
      right: 20px
      img
        width: 16px
        height: 16px
        cursor: pointer
    &__text
      text-align: center
      margin-bottom: 25px
    &__btn
      display: flex
      justify-content: center
      align-items: center
    &__inner
      position: relative
      max-width: 350px
      margin: 0 auto
      width: 100%
      box-shadow: 5px 8px 70px rgba(0, 0, 0, 0.06)
      background: #fff
      padding: 55px 40px 40px
    &__phone
      text-align: center
      color: #1bb3b2
      font-size: 18px
      font-weight: 500
      display: block

  @media screen and (max-width: 1260px)
    .app-widget
      &__head
        flex-direction: column
      &__title
        margin: 0 0 25px
      .reset-all
        margin: 25px 0 0
    .body-selection
      flex-wrap: wrap
      &__item
        width: calc(50% - 40px)
        flex: 0 0 calc(50% - 40px)
        margin-right: 40px
        margin-bottom: 40px

  @media screen and (max-width: 1150px)
    .app-widget
      &__inner
        padding-left: 20px
        padding-right: 20px
    .body-selection
      &__item
        margin-right: 40px

    .time-to-visit
      &__value
        font-size: 18px

    .head-selection
      justify-content: space-between
      &__item
        width: auto
        margin-right: 30px

  @media screen and (max-width: 950px)
    .body-selection
      flex-wrap: wrap
      justify-content: space-between
      &__item
        width: calc(50% - 20px)
        max-width: 100%
        margin-right: 0
        margin-bottom: 35px

  @media screen and (max-width: 700px)
    .head-selection__item:not(.head-selection__item--clear)
      display: none

  @media screen and (max-width: 650px)
    .body-selection
      &__item
        width: 100%
        flex: 0 0 100%
        &:last-child
          margin-bottom: 0

  @media screen and (max-width: 600px)
    .app-widget__submit
      flex-direction: column
      .terms
        order: -1
        font-size: 13px

      .button
        margin-top: 25px
        margin-right: 0

  @media screen and (max-width: 480px)
    .app-widget
      &__inner
        padding-left: 15px
        padding-right: 15px

      &__title
        font-size: 24px
      &__head
        margin-bottom: 35px
        padding-bottom: 20px

    .age-picker
      padding: 20px
      &__inner
        padding: 40px 20px!important

      &__title
        font-size: 18px!important
      &__form
        input
          font-size: 15px!important
          padding-left: 15px!important
          padding-right: 15px!important
          &::placeholder
            font-size: 15px!important


</style>
