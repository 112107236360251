<template>
  <div>
    <div class="age-input">
      <MaskedInput v-model="birthDateState.fullDateWithMask"
                  mask="11 / 11 / 1111"
                  placeholder="День/Месяц/Год"
                  type="tel"
                  class="input"
                  @input="onInput"
                  @keyup.enter.native="onPressEnter"
                  >
      </MaskedInput>
      <div class="age-input__action">
        <button v-if="inPopup"
                class="button"
                @click="setAge"
                :disabled="birthDateState.fullDateWithoutMask.length != 8 || errorBirthDateFormat != ''">{{button}}</button>
        <a v-else 
          @click="changeBirthdayPopup"
          class="link"
          :disabled="birthDateState.fullDateWithoutMask.length != 8 || errorBirthDateFormat != ''">
          {{button}}
        </a>
      </div>
    </div>
    <div v-if="errorBirthDateFormat" class="age-input__error">{{errorBirthDateFormat}}</div>
  </div>
</template>

<script>

import moment from 'moment';
import MaskedInput from 'vue-masked-input';

export default {
  props: [
    'button',
    'birthDateState',
    'setStateBirthDate',
    'inPopup',
    'toggleChangeBirthdatePopup',
  ],
  data(){
    
    return {
      errorBirthDateFormat: '',
    }
    
  },

  components: {
    MaskedInput
  },
  methods: {
    changeBirthdayPopup() {
      if (this.errorBirthDateFormat=='')
        this.toggleChangeBirthdatePopup();
    },

    onPressEnter(){
      if(this.inPopup){
        this.setAge();
      }
    },

    setAge(){
      this.setStateBirthDate('formattedDate', this.birthDateState.fullDateWithoutMask);
      this.setStateBirthDate('ageValue', null, this.errorBirthDateFormat);
    },

    onInput(value){
      //this.setStateBirthDate('fullDateWithoutMask', value);
      this.setStateBirthDate('fullDateWithoutMask', value);
      this.validateBirthDate();
    },

    validateBirthDate(){
      const date = this.birthDateState.fullDateWithoutMask;
      
      const birthDateDay = date.substr(0, 2);
      const birthDateMonth = date.substr(2, 2);
      const birthDateYear = date.substr(-4);

      const currentYear = new Date().getFullYear();

      const parseDate = moment(`${birthDateYear}-${birthDateMonth}-${birthDateDay}`);
      
      this.errorBirthDateFormat = '';

      if( date.length == 8 ) {
        if( !( parseDate.isValid() && birthDateYear <= currentYear ) || (birthDateYear < 1900 || birthDateYear > currentYear) ) {
          this.errorBirthDateFormat = 'Некорректная дата';
          if(birthDateYear < 1900 || birthDateYear > currentYear){
            this.errorBirthDateFormat = `Некорректная дата. Допустимое значение 1900 - ${currentYear} гг.`
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .age-input
    display: flex

    input
      max-width: 220px

    &__action
      display: flex
      align-items: center
      margin-left: 15px
    a
      cursor: pointer
      color: #1bb3b2
      font-size: 15px

    .button
      flex-shrink: 0
      font-size: 14px!important

    &__error
      color: red
      margin-top: 8px
      font-size: 13px
      
</style>