

const getErrorMessage = (error) => {
    return {
        group: 'alerts',
        type: 'error',
        title: 'Произошла ошибка',
        text: error,
        position: 'bottom right'
    }
}

const compareDates = (oldDate, newDate) => {
    const date1 = {};
    date1.year = new Date(oldDate).getFullYear();
    date1.month = new Date(oldDate).getMonth();
    date1.day = new Date(oldDate).getDate();   

    const date2 = {};
    date2.year = new Date(newDate).getFullYear();
    date2.month = new Date(newDate).getMonth();
    date2.day = new Date(newDate).getDate(); 

    return (
        date1.year == date2.year &&
        date1.month == date2.month &&
        date1.day == date2.day
    )
}

export {
    getErrorMessage,
    compareDates
}