<template>
  <div class="form">
    <div class="title">Записать:
      <span class="yourself" :class="{active: !userData.anotherPatient}" @click="toggleTypeOfPatient">себя</span> или
      <span class="another" :class="{active: userData.anotherPatient}" @click="toggleTypeOfPatient">другого пациента</span>
    </div>
    <div class="row">
      <div class="field">
        <VueSuggestions
          ref="surname"
          :model.sync="userData.patientSurname"
          placeholder="Фамилия"
          :options="suggestionSurname"
          class="input"
          :class="{'is-error': !userData.patientSurname && userData.touchedValidateField }">
        </VueSuggestions>
      </div>

      <div class="field">
        <VueSuggestions
          ref="name"
          :model.sync="userData.patientName"
          placeholder="Имя"
          :options="suggestionName"
          class="input"
          :class="{'is-error': !userData.patientName && userData.touchedValidateField }">
        </VueSuggestions>
      </div>

      <div class="field">
        <VueSuggestions
          :model.sync="userData.patientPatronymic"
          placeholder="Отчество"
          :options="suggestionPatronymic"
          class="input"
          ref="patronymicRef"
          :class="{'is-error': !userData.patientPatronymic && userData.touchedValidateField }">
        </VueSuggestions>
      </div>
    </div>
    <div class="field field--datepicker">
      <AgeInput
        button="Изменить дату рождения"
        :birthDateState="birthDateState"
        :setStateBirthDate="setStateBirthDate"
        :toggleChangeBirthdatePopup="toggleChangeBirthdatePopup"
      ></AgeInput>
    </div>
    <div class="field">
      <MaskedInput v-if="userData.anotherPatient"
                    v-model="userData.additionalInfo"
                    mask="\+\7 (111) 111-1111"
                    placeholder="Номер телефона пациента для связи"
                    type="tel"
                    class="input another-patient-textarea">
      </MaskedInput>
    </div>
    <div class="field textarea">
      <textarea v-model="userData.comment"
                rows="2"
                placeholder="Ваш комментарий"
                class="input"></textarea>
    </div>
  </div>
</template>
<script>

import AgeInput from './AgeInput';
import VueSuggestions from 'vue-suggestions';
import MaskedInput from 'vue-masked-input';

export default {
  props: [
    'userData',
    'setStateTypeOfPatient',
    'errorBirthDateFormat',
    'birthDateState',
    'setStateBirthDate',
    'toggleChangeBirthdatePopup',
  ],
  components: {
    AgeInput,
    VueSuggestions,
    MaskedInput
  },
  data(){
    return {
      suggestionName: {
        token: 'ecf3b29fd67c6e155d574d9f1d4f1c566b570e50',
        type: "NAME",
        params: {
          parts: ["NAME"]
        }
      },
      suggestionSurname: {
        token: 'ecf3b29fd67c6e155d574d9f1d4f1c566b570e50',
        type: "NAME",
        params: {
          parts: ["SURNAME"]
        }
      },
      suggestionPatronymic: {
        token: 'ecf3b29fd67c6e155d574d9f1d4f1c566b570e50',
        type: "NAME",
        params: {
          parts: ["PATRONYMIC"]
        }
      },
    }
  },
  methods: {
    toggleTypeOfPatient(){
      this.setStateTypeOfPatient(!this.userData.anotherPatient)
    },
  },
  mounted: function () {
    this.$refs.patronymicRef.$el.setAttribute('autocomplete', 'additional-name');

    const refKeys = Object.values(this.$refs);
    for (let input of refKeys) {
      input.$el.addEventListener('focus', (event) => {
        event.preventDefault();
        event.target.scrollIntoView({ block: "center", inline: "nearest"});
      });
    }
  }
}
</script>

<style scoped lang="sass">

  .error-text
    color: red
    font-size: 14px

  .title
    font-size: 24px
    text-transform: uppercase
    margin-bottom: 30px

  .row
    display: flex

  .yourself,
  .another
    cursor: pointer
    border-bottom: 1px dotted rgba(0,0,0,.3)
    transition: .3s

    &.active
      color: #1bb3b2
      border-bottom: transparent

  .field
    margin-right: 20px
    margin-bottom: 20px
    flex-grow: 1
    &.textarea
      margin-right: 0
      textarea
        margin-bottom: 20px
        &:last-child
          margin-bottom: 0
    &:last-child
      margin-right: 0
    &--datepicker
      max-width: 500px
      width: 100%
      min-width: 205px

    &--agepicker
      display: flex
      max-width: 500px
      span
        margin-top: 20px
        margin-left: 15px
        font-size: 14px

    &--phone
      max-width: 230px

    &--sms
      max-width: 500px

  @media screen and (max-width: 700px)
    .row
      display: block

    .field
      margin-right: 0!important

  @media screen and (max-width: 800px)
    .field
      &--sms
        margin-left: 0

  @media screen and (max-width: 480px)
    input
      font-size: 16px!important
      &::placeholder
        font-size: 16px!important
    textarea
      font-size: 16px!important
      height: 120px
      &::placeholder
        font-size: 16px!important

</style>
