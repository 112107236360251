<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  
}
</script>

<style global lang="sass">
  #app
    .app-widget
      ::-webkit-scrollbar-thumb
        background: #EEEEEE
      ::-webkit-scrollbar
        width: 5px
        border-radius: 4px

      &__foot
        position: relative
        &.is-hide
          opacity: .3
          &:after
            content: ''
            position: absolute
            top: 0
            right: 0
            left: 0
            bottom: 0
            z-index: 3

      input.input,
      .suggestions-input,
      textarea.input
        width: 100%
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06)
        border: 1px solid #dedede
        background: #ffffff
        border-radius: 4px
        padding-left: 25px
        padding-right: 25px
        outline: none
        font-size: 18px
        font-weight: 300
        color: #3a3a3a
        &::placeholder
          font-family: 'Roboto'
          font-size: 18px
          font-weight: 300
          color: #3a3a3a

        &.is-error
          border-color: red
      input.input,
      .suggestions-input
        height: 60px
      textarea.input
        width: 100%
        padding: 25px
        line-height: 1.4
        height: unset
        font-family: 'Roboto'

      .button
        display: flex
        align-items: center
        justify-content: center
        height: 60px
        background: #1bb3b2
        padding: 0 22px
        color: #fff
        text-align: center
        font-size: 18px
        border-radius: 4px
        cursor: pointer
        transition: .3s
        text-transform: uppercase
        outline: none
        border: none
        &:hover
          background: #20bfbe

      [disabled]
        cursor: not-allowed
        background: #F3F3F3!important
        color: #000

      .vue-datepicker
        input
          @extend .input
          background: url('../public/img/appointment-widget-images/calendar-icon.svg') calc(100% - 20px) 50% no-repeat, #ffffff!important

      .vdp-datepicker
        &__calendar
          border: none
          .cell
            border-radius: 50%
            font-size: 14px
            height: 42px
            line-height: 42px
            &.day-header
              text-transform: uppercase
            &:not(.disabled):hover
              background: #f5f5f5
              border-color: transparent
            &.selected
              background: #139594
              color: #fff!important
              &.disabled
                color: #fff!important
              &:hover
                background: #139594
                color: #fff

      .multiselect__single
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

      .multiselect
        min-height: auto
        .multiselect__option
          font-weight: 300
          &--highlight
            background: #ECF6EF
            color: #000

        .multiselect__tags
          cursor: pointer
          border: none
          border-bottom: 1px dotted #bfbfbf
          border-radius: 0
          padding-left: 0
          padding-right: 24px
          min-height: auto
        .multiselect__placeholder,
        .multiselect__single
          font-size: 22px
          font-weight: 300
          color: #282828
          margin-bottom: 3px
          padding-top: 0

        .multiselect__select
          padding-right: 0
          width: 20px
          
          &:before
            color: #000
            border-color: #000 transparent transparent

        .multiselect__single
          margin-bottom: 6px
          padding-left: 0
        
        .multiselect__input
          font-size: 22px
          font-weight: 300
          color: #282828
          margin-bottom: 3px
          padding-left: 0

        .clinic-address
          font-size: 14px
          margin-top: 6px
          opacity: .6

      .suggestions-addon
        right: 0!important
        left: unset!important
        transform: scale(0.3)


    .head-selection__item:first-child
      .multiselect__content-wrapper
        max-height: none!important

    @media screen and (max-width: 1100px)
      .app-widget
        .multiselect
          .multiselect__placeholder,
          .multiselect__single,
          .multiselect__input
            font-size: 18px

    @media screen and (max-width: 1080px)
      .app-widget
        &__inner
          margin-top: 85px
</style>
