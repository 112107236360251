<template>
  <div class="phone-confirm-popup">
    <div class="phone-confirm-popup__inner row">
      <div class="close" @click="closeMethod">
        <img src="../../public/img/appointment-widget-images/cross.svg">
      </div>
      <div v-if="!phoneConfirmState.isRequestSend" class="phone-field">

        <input
          v-model="userData.phone"
          type="text"
          class="input"
          v-mask="['+7 (###) ###-####']"
          placeholder="+7 (___) ___-____"
          @input="setPhoneValue"
          v-on:keyup="keyUpPhone"
          :disabled="userData.isPhoneConfirmed"
          ref="inputPhoneRef"
        >

      </div>
      <div class="action-field">
        <div class="sms">
          <div v-if="userData.isPhoneConfirmed">
            Ваш телефон подтвержден
          </div>

          <div v-else>
              <div v-if="!phoneConfirmState.isRequestSend" class="request-code">
                <button class="request-code__btn button"
                        :disabled="disabledRules() || sendingProcessPreloader"
                        @click="sendRequestCode"
                >Получить код</button>
                <div class="request-code__label">Код будет отправлен по SMS</div>
              </div>

              <div v-else class="input-code">
                <div class="input-code__field">
                  <input v-model="phoneConfirmState.confirmCode"
                         type="text"
                         placeholder="Код из SMS"
                         v-mask="['####']"
                         v-on:keyup="keyUpCode"
                         class="input"
                         ref="inputCodeRef"
                  >
                  <button class="request-code__btn button"
                          :disabled="!phoneConfirmState.confirmCode"
                          @click="confirmPhone">Подтвердить</button>

                  <div class="input-code__counter">Повтор через {{counterTimer}} сек.</div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '../api';
import {mask} from 'vue-the-mask'

export default {
  props: [
    'userData',
    'phoneConfirmState',
    'validatePhoneFormat',
    'selectedData',
    'setStatePhoneConfirmRequestSend',
    'setConfirmCode',
    'callbackAfterCreateAppointment',
    'preloaderToggle',
    'setErrorMessage',
    'closeMethod',
    'setPhoneValue'
  ],
  directives: {mask},
  data(){
    return {
      counterTimer: 60,
      counterStarted: false,
      counterInstanse: '',
      sendingProcessPreloader: false,
    }
  },
  methods: {

    keyUpPhone(e) {
      if (e.keyCode==13 && !this.disabledRules() && this.sendingProcessPreloader===false) {
        this.sendRequestCode();
      }
    },

    keyUpCode(e) {
      if (e.keyCode==13 && this.phoneConfirmState.confirmCode) {
        this.confirmPhone();
      }
    },

    sendRequestCode(){
      this.sendingProcessPreloader = true;

      API.post('/confirmphone/send', {
          phone: this.userData.phone
        },
        {
          headers: {'Authorization': `bearer ${this.userData.jwtToken}`}
        })
        .then(() => {
          this.sendingProcessPreloader = false;
          this.setStatePhoneConfirmRequestSend(true);
          this.counterDown();

          var th = this;
          
          setTimeout(()=>{
            th.$refs.inputCodeRef.focus();
          }, 400);
          
        })
      .catch(err => this.setErrorMessage(err))
    },

    confirmPhone(){
      this.preloaderToggle();
      API.post('/confirmphone/confirm', {
        code: this.phoneConfirmState.confirmCode
      },
      {
        headers: {'Authorization': `bearer ${this.userData.jwtToken}`}
      })
      .then(res => this.callbackAfterCreateAppointment(res.data))
      .catch(err => {
        this.preloaderToggle();
        this.setErrorMessage(err);
      })
    },

    counterDown(){
      this.counterStarted = true;
      this.counterInstanse = setInterval(() => {
        if(this.counterTimer <= 0) {
          this.stopCounter()
        } else {
          --this.counterTimer
        }
      }, 1000);
    },

    stopCounter(){
      if(this.counterStarted) {
        clearInterval(this.counterInstanse);
        this.counterTimer = 60;
        this.setStatePhoneConfirmRequestSend(false);
        this.setConfirmCode('');
        this.counterStarted = false;
      }
    },

    setFocus() {
      this.$refs.inputPhoneRef.focus();
    },

    disabledRules(){
      return !this.phoneConfirmState.phoneFormatIsValid
    },
  }

}
</script>

<style lang="sass" scoped>
  .phone-confirm-popup
    position: fixed
    display: flex
    align-items: center
    justify-content: center
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 99
    background: rgba(#000, .15)

    &__inner
      max-width: 350px
      margin: 0 auto
      width: 100%
      box-shadow: 5px 8px 70px rgba(0, 0, 0, 0.06)
      background: #fff
      padding: 45px
      position: relative

    .close
      position: absolute
      top: 20px
      right: 20px
      img
        width: 20px
        height: 20px

  .phone-field
    margin-bottom: 15px

  .sms
    .input-code
      display: flex
      align-items: center

      &__btn
        margin-top: 20px

      &__counter
        font-size: 14px
        margin-left: 15px
        white-space: nowrap

    .request-code
      &__btn
        width: 100%
        display: flex
        align-items: center
        margin-bottom: 20px
        margin-top: 20px
        font-size: 14px!important
      &__label
        font-size: 14px
        text-align: center

    @media screen and (max-width: 480px)
      .input-code
        flex-wrap: wrap
        &__field
          width: 100%
          margin-bottom: 15px
        &__counter
          width: 60%
          margin-left: 0
          padding-left: 20px
        &__btn
          width: 40%
          margin-left: 0
          font-size: 15px!important
</style>


