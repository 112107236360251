<template>
  <div class="select">
    <div class="select__label">{{label}}</div>
    <div class="select__ctrl">
      <multiselect :value="value"
                  :class="{'selected': value }"
                  :options="list"
                  :placeholder="placeholder"
                  :show-labels="false"
                  track-by="name"
                  label="name"
                  @select="onSelect">

          <template slot="singleLabel" slot-scope="props">
            <div class="single-label-value" v-html="printLabelText(props)"></div>
          </template>
          <template slot="noResult">Ничего не найдено</template>
          <template slot="noOptions">Список пуст</template>
          <template slot="option" slot-scope="props">
            <div v-html="printLabelText(props, 'options')"></div>
          </template>
      </multiselect>
      <div v-if="value" class="select__clear" @click="clear">
        <img src="../../public/img/appointment-widget-images/cross.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'

export default {
  name: 'Select',
  components: { Multiselect },
  props: [
    'label',
    'list',
    'value',
    'placeholder',
    'onChange',
    'type',
    'clearSelected'
  ],
  data () {
    return {
    }
  },
  methods: {
    onSelect(value) {
      this.onChange({ field: this.type, searchType: 'global' }, value);
    },
    formatDoctorName: function (value) {
      if (!value) return ''
      value = value.split(' ')
      return `${value[0]} ${value[1] && value[1][0]}.${value[2] && value[2][0]}.`
    },
    clear(){
      this.clearSelected(this.type);
    },

    printLabelText(props, type='single'){
      if(this.type === 'doctors') return this.formatDoctorName(props.option.name);
      if(this.type === 'clinics') {
        return type === 'options'
        ? `<div class="clinic-metro">${props.option.name} (м.${props.option.metro})</div>
           <div class="clinic-address">${props.option.address}</div>`
        : props.option.name
      }
      return props.option.name;
    }
  },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="sass">
  .select
    &__label
      font-size: 14px
      font-weight: 500
      margin-bottom: 10px

    &__ctrl
      display: flex

    &__clear
      cursor: pointer
      margin-left: 10px
      margin-top: 9px
      img
        width: 12px
        height: 12px
        margin-bottom: 3px

  .single-label-value
    max-width: 300px
    text-overflow: ellipsis
    overflow: hidden

</style>
